<template>
    <div>
        <!-- Search bar -->
        <div class="bg-white m-8 sm:m-8 sm:mt-0 sm:bg-transparent rounded-md drop-shadow-2xl relative z-10">
            <!-- Tabs -->
            <div class="block sm:inline-block sm:bg-white sm:rounded-t-md">
                <div class="border-b border-gray-200">
                    <div class="-mb-px flex p-0 px-4 space-x-8" aria-label="Tabs">
                        <div v-for="tab in tabs" :key="tab.name" @click="setTab(tab)" :class="[tab.current ? 'border-primary text-primary' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-4 font-medium text-sm hover:cursor-pointer']" :aria-current="tab.current ? 'page' : undefined">{{ tab.name }}</div>
                    </div>
                </div>
            </div>
            <!-- Input -->
            <div class="relative flex flex-col sm:flex-row rounded-b-md rounded-tr-md">
                <!-- Membership -->
                <Listbox as="div" v-model="selectedMembership" v-if="tabs.find(tab => tab.name.includes('Školné')).current">
                    <div class="relative">
                        <ListboxButton class="hover:bg-primary-light hover:cursor-pointer relative rounded-bl-md w-full sm:w-[262px] h-[64px] cursor-default bg-white py-2 pl-4 pr-10 text-left focus:border-primary focus:outline-none sm:text-sm">
                            <span class="block truncate">{{ selectedMembership ? 'Nezáleží' : selectedMembership }}</span>
                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </ListboxButton>

                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ListboxOption as="template" v-for="mem in memberships" :key="mem" :value="mem" v-slot="{ active, selected }">
                                    <li :class="[active ? 'text-primary-text bg-primary-light' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ mem }}</span>

                                    <span v-if="selected" :class="[active ? 'text-primary' : 'text-primary', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    </li>
                                </ListboxOption>
                            </ListboxOptions>
                        </transition>
                    </div>
                </Listbox>
                <!-- Fields (specializations) -->
                <Listbox multiple as="div" v-model="selectedSpec" v-if="tabs.find(tab => tab.name.includes('Obory')).current">
                    <div class="relative">
                        <ListboxButton class="hover:bg-primary-light hover:cursor-pointer relative rounded-bl-md w-full sm:w-[262px] h-[64px] cursor-default bg-white py-2 pl-4 pr-10 text-left focus:border-indigo-500 focus:outline-none sm:text-sm">
                            <span class="block truncate">{{ selectedSpec.length > 0 ? selectedSpec.join(', ') : 'Vše' }}</span>
                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </ListboxButton>

                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ListboxOption as="template" v-for="spec in specializations" :key="spec.text" :value="spec.text" v-slot="{ active, selected }">
                                    <li :class="[active ? 'text-primary-text bg-primary-light' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block']">{{ spec.title }}</span>

                                    <span v-if="selected" :class="[active ? 'text-primary' : 'text-primary', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    </li>
                                </ListboxOption>
                            </ListboxOptions>
                        </transition>
                    </div>
                </Listbox>
                <!-- Area -->
                <Listbox multiple as="div" v-model="selected" v-if="tabs.find(tab => tab.name.includes('Lokace')).current">
                    <div class="relative">
                        <ListboxButton class="hover:bg-primary-light hover:cursor-pointer relative rounded-bl-md w-full sm:w-[262px] h-[64px] cursor-default bg-white py-2 pl-4 pr-10 text-left focus:border-indigo-500 focus:outline-none sm:text-sm">
                            <span class="block truncate">{{ selected.length > 0 ? selected.join(', ') : 'Vše' }}</span>
                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </ListboxButton>

                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ListboxOption as="template" v-for="area in areas" :key="area" :value="area" v-slot="{ active, selected }">
                                    <li :class="[active ? 'text-primary-text bg-primary-light' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ area }}</span>

                                    <span v-if="selected" :class="[active ? 'text-primary' : 'text-primary', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                    </li>
                                </ListboxOption>
                            </ListboxOptions>
                        </transition>
                    </div>
                </Listbox>
                <!-- Phrase -->
                <input v-model='phrase' @input="phraseChanged" type="text" class="w-full p-4 py-5 outline-none text-primary-text font-semibold placeholder:font-normal sm:rounded-tr-md rounded-bl-md border-t sm:border-t-0 sm:rounded-bl-none rounded-br-md sm:border-l sm:border-[#E5E7EA]" placeholder="Hledat školy...">
                <!-- Button -->
                <button @click="searchClick" class="flex absolute right-3 bottom-3 bg-primary hover:bg-primary-dark text-white p-2 rounded-md">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                    <span class="hidden lg:inline px-2 pt-[2px] font-semibold">Hledat</span>
                </button>
            </div>
        </div>
        <!-- Tip -->
        <div class="m-8 mb-4 sm:mb-8">
            <div class="flex align-center">
                <div class="w-6 h-6">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-secondary text-sm">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                    </svg>
                </div>
                <p class="ml-1.5 pt-[2px] text-secondary text-sm">Vyhledejte název oboru, kód oboru, jméno školy či lokaci. Využijte filtry na třídění výsledků.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import specializationsData from '../assets/specializations.json';

export default {
    name: 'SearchBar',
    data() {
        return {
            phrase: '',

            tabs: [
                { name: 'Lokace', current: true },
                { name: 'Obory', current: false },
                { name: 'Školné', current: false }
            ],
            
            specializations: this.traverseSpecializations(),
            selectedSpec: [],

            memberships: [
                'Nezáleží',
                'Ano',
                'Ne'
            ],
            selectedMembership: 'Nezáleží',

            areas: [...Array(22).keys()].map(number => `Praha ${number+1}`),
            selected: []
        }
    },
    mounted() {
        if (this.initial) {
            // S object
            this.phrase = this.initial.phrase;
            this.selected = this.initial.area;
            this.selectedMembership = this.initial.membership;
            this.selectedSpec = this.initial.specializations;
        }
    },
    methods: {
        setTab(tab) {
            this.tabs.forEach(tab => tab.current = false);
            tab.current = true;
        },
        
        phraseChanged() {
            if (!this.wait)
                this.$emit('sChange', {
                    phrase: this.phrase,
                    area: this.selected,
                    membership: this.selectedMembership,
                    specializations: this.selectedSpec
                });
        },

        traverseSpecializations() {
            let result = [];

            specializationsData.forEach(spec => {
                result.push(spec.items);
            });

            result = result.flat();

            return result.flat().sort((a, b) => a.title.localeCompare(b.title, 'cs'));
        },

        searchClick() {
            this.$emit('sChange', {
                phrase: this.phrase,
                area: this.selected,
                membership: this.selectedMembership,
                specializations: this.selectedSpec
            });
        }
    },
    components: {
        Listbox, 
        ListboxButton, 
        ListboxLabel, 
        ListboxOption, 
        ListboxOptions,
        CheckIcon, 
        ChevronUpDownIcon
    },
    props: {
        initial: Object,
        wait: Boolean
    },
    watch: {
        selectedSpec: function(_new, old) {
            if (!this.wait)
                this.$emit('sChange', {
                    phrase: this.phrase,
                    area: this.selected,
                    membership: this.selectedMembership,
                    specializations: this.selectedSpec
                });
        },
        selectedMembership: function() {
            if (!this.wait)
                this.$emit('sChange', {
                    phrase: this.phrase,
                    area: this.selected,
                    membership: this.selectedMembership,
                    specializations: this.selectedSpec
                });
        },
        selected: function() {
            if (!this.wait)
                this.$emit('sChange', {
                    phrase: this.phrase,
                    area: this.selected,
                    membership: this.selectedMembership,
                    specializations: this.selectedSpec
                });
        }
    }
}
</script>
