<template>
    <!-- Hlavička -->
    <HeaderBar></HeaderBar>
    <!-- Hero -->
    <div style="background-position: right top, left;" v-if="$route.name == 'home'" class="bg-no-repeat bg-[url(/pattern_mobile.png)] md:bg-[url(/hero_right.png),_url()] xl:bg-[url(/hero_right.png),_url(/hero_left.png)] bg-secondary-light md:bg-contain md:bg-no-repeat pb-4">
        <div class="md:max-w-xl lg:mx-auto lg:max-w-5xl">
            <h1 class="text-3xl lg:text-4xl lg:max-w-xl p-8 font-bold text-primary sm:text-left sm:p-8 sm:pt-16">Najdi střední školu,<br class="hidden sm:block"> která tě bude bavit</h1>
            <p class="text-lg font-medium text-secondary-text p-8 pb-0 sm:pb-8 pt-0 sm:text-left sm:p-8 sm:pt-0 lg:max-w-[65%] opacity-70">Veletrh a aplikace <strong>Schola Pragensis</strong> vás provede výběrem střední školy. Udělejte si přehled <br class="block sm:hidden"> o stovkách škol na jednom místě <strong>23. 11. – 25. 11. 2023</strong> <br>v Kongresovém centru Praha. </p>
            <SearchBar @s-change="search" :wait="true"></SearchBar>
        </div>
    </div>
    <!-- Router -->
    <router-view></router-view>
    <!-- Footer -->
    <FooterBar></FooterBar>  
    <!-- Notice -->
    <TransitionRoot as="template" :show="modal">
        <Dialog as="div" class="relative z-10" @close="closeModal()">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[600px] sm:p-6">
                <div>
                    <div class="text-center">
                        <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Přijďte na veletrh!</DialogTitle>
                        <div class="mt-2">
                            <p class="text-sm text-gray-500">Veletrh je již za rohem! Udělejte si přehled o stovkách škol na jednom místě 23. 11. – 25. 11. 2023 v Kongresovém centru Praha! Stáhněte si i naši aplikaci:</p>

                            <div class="flex my-6">
                                <div class="hover:bg-primary-light hover:cursor-pointer hover:border-primary flex items-center border w-auto rounded-lg px-4 py-2 w-52">
                                    <a class="flex items-center" target="_blank" href="https://play.google.com/store/apps/details?id=com.solutions200.scholapragensis">
                                        <img src="https://cdn-icons-png.flaticon.com/512/888/888857.png" class="w-7 md:w-8">
                                        <div class="text-left ml-3">
                                            <p class='text-xs text-primary'>Stáhnout na</p>
                                            <p class="text-sm md:text-base"> Google Play</p>
                                        </div>
                                    </a>
                                </div>
                        
                                <!-- hover:bg-[#FEF4EB] hover:cursor-pointer hover:border-[#F08F42] -->
                                <div class="hover:bg-primary-light hover:cursor-pointer hover:border-primary w-[208px] flex items-center border w-auto rounded-lg px-4 py-2 w-44 ml-3">
                                    <a class="flex items-center" target="_blank" href="https://apps.apple.com/cz/app/schola-pragensis/id6444214298?l=cs">
                                        <img src="https://cdn-icons-png.flaticon.com/512/888/888841.png" class="w-7 md:w-8">
                                        <div class="text-left ml-3">
                                            <p class='text-xs text-primary'>Stáhnout na</p>
                                            <p class="text-sm md:text-base"> App Store </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-6">
                    <button type="button" class="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary" @click="closeModal()">Zavřít</button>
                </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import HeaderBar from './components/HeaderBar.vue';
import SearchBar from './components/SearchBar.vue';
import FooterBar from './components/FooterBar.vue';
import ExternalText from './components/ExternalText.vue';

import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import Cookies from 'js-cookie';

export default {
    name: 'App',
    data() {
        return {
           phrase: '',
           searchObject: null,
           modal: false
        }
    },
    created() {
        const modalCookie = Cookies.get('modal');
        
        if (modalCookie == undefined || modalCookie == false || modalCookie == 'false') {
            this.modal = true;
        }
    },
    components: {
        HeaderBar,
        SearchBar,
        FooterBar,
        ExternalText,
        Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, CheckIcon
    },
    methods: {
        handleChange(e) {
            this.searchObject = e;
        },
        search(e) {
            // named route with params to let the router build the url
            this.$router.push({ name: 'search', query: { s: JSON.stringify(e) }});
        },
        closeModal() {
            this.modal = false;
            Cookies.set('modal', true, { expires: 7 }); // Hodnota uložena na 7 dní
        }
    }
}
</script>
